/**
 * Common store module
 *
 * Data in this module should meet the following criteria:
 *
 * - Master Data which is *not* account-specific (no account ID field)
 * - Session/user data - includes current user and account, roles, etc
 * - General UI state
 */

/* eslint-disable no-shadow */
import { make } from 'vuex-pathify';

import _ from 'underscore';

import { isBlank } from 'adready-api/helpers/common';
import { buildQueryString } from '~/helpers/global/url-helpers';
import {
  PAGINATION_DEFAULT_ITEM_PER_PAGE,
  ENUM_IS_WATCH_STATS,
  DEMO_ADVERTISERS,
  KEY_DEMO_ADVERTISER_ID,
} from '~/constant';
import { getCampaignDateRange, isDemoInstance, isFlamingoInstance } from '~/util/utility-functions';

const state = {
  plan: {},
  newCampaignLoader: true,
  // Show filters boolean
  showFilters: false,

  // Boolean for MNI Account
  isMniAccount: false,

  // select one Campaign Type
  selectOneCampaignType: '',

  // List of all product categories
  productCategories: [],

  // List of all roles
  allRoles: [],

  // List of all users
  allUsers: [],

  // List of all organizations
  allOrganizations: [],

  // List of all applications
  allApplications: [],

  // List of all device types
  allDeviceTypes: [],

  // List of all brand safety levels
  allBrandSafetyLevels: [],

  // List of all verification partners
  allVerificationPartners: [],

  // List of all users for the current loggd in user
  allUsersForCurrentUser: [],

  // List of accounts/roles the user has, as read from their JWT
  userAccountRoles: [],

  // Map<Role Name, 1> of roles for the currently active account
  currentAccountRoles: {},

  // List of accounts user has access to, loaded from DB
  userAccounts: [],

  // List of all accounts
  accounts: [],

  // Logged-in User ID, read from JWT
  currentUserId: null,

  // Logged-in User
  currentUser: null,

  // List of user global roles.
  currentUserRoles: [],

  // whilte label domains
  whitelabelDomains: [],

  // Currently active account ID
  currentAccountId: null,
  // For Demo active advertiser name
  advName: null,

  // For Demo active advertiser name
  demoSelectedAdvertiserName: null,

  // Account wise data map store respective data as mentioned in name.
  accountMap: {},
  accountUsersMap: {},
  accountRolesMap: {},

  // Flag used to avoid race condition on watches when switching accounts.
  //
  // Since we reset the state on switch, multiple watches can fire
  // simultaneously, causing multiple data loads. We set this true before
  // switching and then reset it from a single location after the switch.
  //
  // Default to true when app starts. This is to avoid a double-load of plan
  // data on startup and will get reset to false by the orderCriteria watch in
  // PlanDashboard while the currentAccountId watch loads the data.
  switchingAccounts: true,

  // Current account object
  account: null,

  // Current selected advertiser object
  advertiser: null,

  // pixel id
  universalPixelId: null,

  // store the conversion windows data
  conversionWindowMap: {},
  ui: {
    modal: null,
  },
  // state for selected option on common header menu.
  selectedMenuOption: '',
  selectedSubMenuOption: '',
  selectedElementId: '',
  // dashboard loading until we get advertiser.
  dashboardLoading: true,
  isWatchStats: ENUM_IS_WATCH_STATS,
  liStatusOptions: [],
  liStatusCount: [],
  liStatusCountForPlans: [],
  plans: [],
  iosPacingData: null,
  orderCriteria: {
    sort: {
      // key/column to sort by
      col: null,
      // direction (order, e.g., asc or desc)
      dir: null,
    },
    page: {
      // page number
      num: 0,
      // records per page
      per: PAGINATION_DEFAULT_ITEM_PER_PAGE,
    },
  },
  totalPlansPages: 0,
  totalPlansCount: 0,
  plansQuery: {},
  spcGroupingData: {
    placement: {},
    publisher: {},
  },
  mediaPublisherCPMData: [],
  activeItem: undefined,
  orgLineItems: [],
  expandedPlanId: undefined,
  refreshCampaignValidation: false,
  showExportTable: false,
  selectedExportTableRow: '',
  isTransformPlanLoading: true,
  planIdForLineItemStatusCountLoading: null,
  isEventLoading: false,
};

const actions = {
  setRefreshCampaignValidation: ({ commit }, payload) => {
    commit('SET_REFRESH_CAMPAIGN_VALIDATION', payload);
  },
  updateActiveItem: ({ commit }, payload) => {
    commit('SET_ACTIVE_ITEM', payload);
  },
  setConversionWindow: ({ commit }, payload) => {
    commit('SET_CONVERSION_WINDOW', payload);
  },
  setNewCampaignLoader: ({ commit }, payload) => {
    commit('SET_NEW_CAMPAIGNS_LOADER', payload);
  },
  setSelectedMenuOptions: ({ commit }, payload) => {
    if (_.isObject(payload)) {
      commit('SET_SELECTED_SUB_MENU_OPTION', payload.subMenu);
      commit('SET_SELECTED_MENU_OPTION', payload.menu);
      commit('SET_SELECTED_ELEMENT_ID', payload.elementId);
      return;
    }
    commit('SET_SELECTED_SUB_MENU_OPTION', '');
    commit('SET_SELECTED_ELEMENT_ID', '');
    commit('SET_SELECTED_MENU_OPTION', payload);
  },
  setIsWatchStats: ({ commit }, payload) => {
    commit('SET_IS_WATCH_STATS', payload);
  },
  setSpcGroupingData: ({ commit }, payload) => {
    commit('SET_SPC_GROUPING_DATA', payload);
  },
  switchAdvertiser: async (ctx, { flipPixelApi, advertiserReportsAPI, advertiserId }) => {
    try {
      const advertiser = ctx.getters.GET_ADVERTISERS_LIST.find((a) => a.id === advertiserId);
      ctx.commit('SET_UPDATE_ADVERTISER', advertiser);
      let universalPixel;
      ctx.commit('SET_IS_WATCH_STATS', {
        IMPRESSION: true,
        REACH: true,
        CONVERSION: true,
        LIFT: true,
      });
      if (!isBlank(advertiser.xandrAdvertiserId)) {
        const pixels = await flipPixelApi.flipPixels(advertiserId);

        if (pixels && _.isArray(pixels)) {
          universalPixel = pixels.find((p) => p.conversion_type === 'Universal');
        }
      }
      ctx.commit('SET_UPDATE_UNIVERSAL_PIXEL_ID', universalPixel ? universalPixel.flip_id : null);

      const newData = [];
      const mediaTypes = await advertiserReportsAPI.mediaTypes(
        advertiser ? advertiser.id : 0,
        buildQueryString({
          advertiser: advertiser ? advertiser.name : '',
          client: ctx.state.account ? ctx.state.account.name : '',
          includeNonFlip: isFlamingoInstance(),
        })
      );
      if (mediaTypes.length !== 0) {
        for (let i = 0; i < mediaTypes.length; i++) {
          newData.push({ value: mediaTypes[i], checked: false });
        }
      }
      let events = [];
      if (
        ctx.state.account &&
        advertiser &&
        !isBlank(ctx.state.account.name) &&
        !isBlank(advertiser.name)
      ) {
        events = await advertiserReportsAPI.events(
          advertiser ? advertiser.id : 0,
          buildQueryString({
            advertiser: advertiser ? advertiser.name : '',
            client: ctx.state.account ? ctx.state.account.name : '',
            xandrId: advertiser ? advertiser.xandrAdvertiserId : '',
          })
        );
        events.forEach((event) => {
          event.open = event.selected;
        });
      }
      if (
        !ctx.state.account ||
        !advertiser ||
        isBlank(ctx.state.account.name) ||
        isBlank(advertiser.name) ||
        isBlank(advertiser.xandrAdvertiserId)
      ) {
        ctx.commit(
          'dashboard/SET_UPDATE_FILTERS_DATA',
          {
            ioOptions: [],
            mediaTypeOptions: newData || [],
            eventOptions: events || [],
            adGroupOptions: [],
          },
          { root: true }
        );
        return;
      }
      const ios = await advertiserReportsAPI.ioNames(
        advertiser ? advertiser.id : 0,
        buildQueryString({
          advertiser: advertiser ? advertiser.name : '',
          client: ctx.state.account ? ctx.state.account.name : '',
          includeNonFlip: isFlamingoInstance(),
        })
      );
      const campaigns = ios || [];
      if (!campaigns.length) {
        if (!isDemoInstance()) {
          ctx.commit('dashboard/SET_RESET_DATES', {}, { root: true });
        }
      } else {
        // Mark recent campaigns as default checked
        const advertiserKeys = Object.keys(DEMO_ADVERTISERS).map((k) => parseInt(k, 10));
        const demoAdvertiserId = parseInt(localStorage.getItem(KEY_DEMO_ADVERTISER_ID), 10);
        const demoAdvertiserMapping = advertiserKeys.includes(demoAdvertiserId)
          ? DEMO_ADVERTISERS[demoAdvertiserId]
          : DEMO_ADVERTISERS[0];
        // Mark recent campaigns as default checked
        if (isDemoInstance()) {
          for (let i = 0; i < campaigns.length; i++) {
            if (demoAdvertiserMapping.selectedCampaign.includes(campaigns[i].value)) {
              campaigns[i].checked = true;
            }
          }
        } else {
          for (let i = 0; i < campaigns.length; i++) {
            campaigns[i].checked = campaigns[i].isRecent;
          }
        }

        if (!isDemoInstance()) {
          const dateRange = getCampaignDateRange(campaigns, true);
          const { rootState } = ctx;
          const { dates } = rootState.dashboard;

          ctx.commit('dashboard/SET_DATES', { ...dates, ...dateRange }, { root: true });
        }
      }
      const adGroupData = await advertiserReportsAPI.adGroupOptions(
        advertiser ? advertiser.id : 0,
        campaigns || [],
        buildQueryString({ includeNonFlip: isFlamingoInstance() })
      );
      if (adGroupData?.data?.length > 0) {
        for (let i = 0; i < adGroupData?.data?.length; i++) {
          adGroupData.data[i].checked = true;
          adGroupData.data[i].value = adGroupData.data[i].lineItemName;
        }
      }

      ctx.commit(
        'dashboard/SET_UPDATE_FILTERS_DATA',
        {
          ioOptions: campaigns || [],
          mediaTypeOptions: newData || [],
          eventOptions: events || [],
          adGroupOptions: adGroupData?.data?.length > 0 ? adGroupData.data : [],
        },
        { root: true }
      );
    } catch (err) {
      console.error('error switching account ->', err);
      if (window.$sentry) {
        if (err._reported !== true) {
          window.$sentry.captureException(err);
          err._reported = true;
        }
      }
      throw err;
    }
  },
  setShowFilters: ({ commit }, payload) => {
    commit('SET_SHOW_FILTERS', payload);
  },
  setIsMniAccount: ({ commit }, payload) => {
    commit('SET_IS_MNI_ACCOUNT', payload);
  },
  setSelectOneCampaignType: ({ commit }, payload) => {
    commit('SET_SELECT_ONE_CAMPAIGN_TYPE', payload);
  },
  setPlans: ({ commit }, payload) => {
    commit('SET_PLANS', payload);
  },
  setTransformPlanLoading: ({ commit }, payload) => {
    commit('SET_TRANSFORM_PLAN_LOADING', payload);
  },
  addPlan: ({ commit }, plan) => {
    commit('ADD_PLAN', plan);
  },
  updatePlans: ({ commit }, payload) => {
    commit('UPDATE_PLANS', payload);
  },
  deletePlan: ({ commit }, plan) => {
    commit('DELETE_PLAN', plan);
  },
  addLineItem: ({ commit }, li) => {
    commit('ADD_LINE_ITEM', li);
  },
  deleteLineItem: ({ commit }, li) => {
    commit('DELETE_LINE_ITEM', li);
  },
  updateLineItem: ({ commit }, payload) => {
    commit('UPDATE_LINE_ITEM', payload);
  },
  updateOrgLineItem: ({ commit }, payload) => {
    commit('UPDATE_ORG_LINE_ITEM', payload);
  },
  updatePlan: ({ commit }, payload) => {
    commit('UPDATE_PLAN', payload);
  },
  updateSelectedAdvertiser: ({ commit }, payload) => {
    commit('SET_UPDATE_ADVERTISER', payload);
  },
  toggleShowExportTable: ({ commit }, payload) => {
    commit('TOGGLE_SHOW_EXPORT_TABLE', payload);
  },
  setSelectedExportTableRow: ({ commit }, payload) => {
    commit('SET_SELECTED_EXPORT_TABLE_ROW', payload);
  },
  setEventLoading: ({ commit }, payload) => {
    commit('SET_EVENT_LOADING', payload);
  },
};

const mutations = {
  ...make.mutations(state),
  SET_TRANSFORM_PLAN_LOADING: (state, payload) => {
    state.isTransformPlanLoading = payload;
  },
  SET_ACTIVE_ITEM: (state, payload) => {
    state.activeItem = payload;
  },
  SET_SHOW_FILTERS: (state, payload) => {
    state.showFilters = payload;
  },
  SET_IS_MNI_ACCOUNT: (state, payload) => {
    state.isMniAccount = payload;
  },
  SET_SELECT_ONE_CAMPAIGN_OPTION: (state, payload) => {
    state.showFilters = payload;
  },
  SET_CONVERSION_WINDOW: (state, payload) => {
    state.conversionWindowMap[payload.windowType] = payload;
  },
  SET_SELECTED_MENU_OPTION: (state, payload) => {
    state.selectedMenuOption = payload;
  },
  SET_SELECTED_ELEMENT_ID: (state, payload) => {
    state.selectedElementId = payload;
  },
  SET_SELECTED_SUB_MENU_OPTION: (state, payload) => {
    state.selectedSubMenuOption = payload;
  },
  SET_IS_WATCH_STATS: (state, payload) => {
    state.isWatchStats = payload;
  },
  SET_SPC_GROUPING_DATA: (state, payload) => {
    state.spcGroupingData = payload;
  },
  SET_RESET_CONVERSION_WINDOW: (state) => {
    state.conversionWindowMap = {};
  },
  SET_NEW_CAMPAIGNS_LOADER: (state, payload) => {
    state.newCampaignLoader = payload;
  },
  SET_UPDATE_UNIVERSAL_PIXEL_ID: (state, id) => {
    state.universalPixelId = id;
  },
  SET_UPDATE_ADVERTISER: (state, advertiser) => {
    state.advertiser = advertiser;
  },
  SET_REFRESH_CAMPAIGN_VALIDATION: (state, payload) => {
    state.refreshCampaignValidation = payload;
  },
  SET_PLANS: (state, payload) => {
    // always replace existing plans with the new ones
    state.totalPlansPages = payload.totalPages;
    state.totalPlansCount = payload.totalElements;
    state.plans = [];
    state.plansMap = {};
    payload.content.forEach((plan) => {
      state.plansMap[plan.id] = plan;
      state.plansMap[plan._uuid] = plan;
      state.plans.push(plan);
    });
  },
  ADD_LINE_ITEM: (state, li) => {
    state.plan.lineItems.push(li);
  },
  ADD_PLAN: (state, plan) => {
    state.plans.unshift(plan);
  },
  SET_EVENT_LOADING(state, payload) {
    state.isEventLoading = payload;
  },

  UPDATE_PLANS: (state, payload) => {
    const { plan } = state;
    if (!plan) {
      return;
    }
    Object.assign(plan, { ...payload.plan, updatedPlan: true });
  },
  DELETE_PLAN: (state, plan) => {
    const planIdx = state.plans.findIndex((p) => p._uuid === plan._uuid);
    if (planIdx < 0) {
      console.warn("warning: couldn't find plan for uuid=", plan._uuid);
      return;
    }
    state.plans.splice(planIdx, 1);
  },
  DELETE_LINE_ITEM: (state, li) => {
    const lineItemIdx = state.plan.lineItems.findIndex((l) => l._uuid === li._uuid);
    if (lineItemIdx < 0) {
      console.warn("warning: couldn't find plan for uuid=", li._uuid);
      return;
    }
    state.plan.lineItems.splice(lineItemIdx, 1);
  },

  UPDATE_LINE_ITEM: (state, payload) => {
    const lineItem = state.plan.lineItems.find((l) => l._uuid === payload._uuid);
    if (!lineItem) {
      console.warn("couldn't find line item for uuid=", payload._uuid);
      return;
    }
    Object.assign(lineItem, payload.lineItem);
  },
  UPDATE_ORG_LINE_ITEM: (state, payload) => {
    const lineItem = state.orgLineItems.find((l) => l.id === payload.lineItem.id);
    if (!lineItem) {
      console.warn("couldn't find line item for id=", payload.lineItem.id);
      return;
    }
    Object.assign(lineItem, payload.lineItem);
  },

  UPDATE_PLAN: (state, payload) => {
    let plan = state.plans.find((p) => p.id === payload.plan.id);
    if (!plan) {
      plan = state.plans.find((p) => p._uuid === payload.plan._uuid);
      if (!plan) {
        console.warn("couldn't find plan for id=", payload.plan.id);
        return;
      }
    }
    Object.assign(plan, payload.plan);
  },
  TOGGLE_SHOW_EXPORT_TABLE: (state, payload) => {
    state.showExportTable = payload;
  },
  SET_SELECTED_EXPORT_TABLE_ROW: (state, payload) => {
    state.selectedExportTableRow = payload;
  },
};

const getters = {
  getAccount: (state) => (id) => {
    return state.accountMap[id];
  },
  getAccountRoles: (state) => (id) => {
    return state.accountRolesMap[id];
  },
  getConversionWindow: (state) => (type) => {
    return state.conversionWindowMap[type];
  },
  GET_ADVERTISERS_LIST(st) {
    if (!st.account) {
      return [];
    }
    return _.sortBy(st.account.advertisers, (a) => a.name.toLowerCase());
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
