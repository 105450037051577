export default {
  primaryColor: '#006987',
  primaryGreen: '#00b3aa',
  primaryRed: '#de284a',
  buttonText: 'light',
  primaryAlt: '#00b3aa',
  'body-background-image': '/assets/bg_background.jpg',
  'login-block-background-color': '#2f323a',
  'body-background-color': '#131619',
  'primary-button-background-color': '#fdce07',
};
