import Vue from 'vue';

import VueGtag from 'vue-gtag';
import store from './store';
import config from './config';
import theme from './plugins/Theme';
import './init/sentry';

import '@/init/plugins';
import '@/init/components';
import '@/init/fonts';

import App from './App.vue';

function changeFavicon(src) {
  const head = document.getElementsByTagName('head')[0];
  const link = document.createElement('link');
  const oldLink = document.getElementById('dynamic-favicon');
  link.id = 'dynamic-favicon';
  link.rel = 'icon';
  link.href = src;
  if (oldLink) {
    head.removeChild(oldLink);
  }
  head.appendChild(link);
}

export const eBus = new Vue();
Vue.config.productionTip = false;
Vue.use(theme);
config.init().then(() => {
  import('./router').then((r) => {
    /*
    if (config.GA_ID && config.GA_ID !== 'undefined') {
      const head = document.getElementsByTagName('head')[0];
      const gtmScript = document.createElement('script');
      gtmScript.src = `https://www.googletagmanager.com/gtag/js?id=${config.GA_ID}`;
      head.appendChild(gtmScript);

      const gtmExecScript = document.createElement('script');
      gtmExecScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', '${config.GA_ID}');
      `;
      head.appendChild(gtmExecScript);
    }
    */
    changeFavicon(
      config.FLIP_FAVICON_URL || 'https://storage.googleapis.com/flip-assets/logos/favicon.ico'
    );
    const tempVue = new Vue({
      router: r.default,
      store,
      render: (h) => h(App),
    });
    if (config.GA_ID && config.GA_ID !== 'undefined') {
      Vue.use(VueGtag, {
        config: { id: config.GA_ID },
      });
    }
    tempVue.$mount('#app');
  });
});

// TODO: add debug flag support
// if (process.env.NODE_ENV !== 'production' || window.location.hostname !== config.PROD_HOSTNAME) {
//   window.Vue = v;
// }
