import Vue from 'vue';

// font-awesome
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from '@fortawesome/vue-fontawesome';

// loads the actual icons for each style
import './fonts-light';
import './fonts-regular';
import './fonts-solid';
import './fonts-duotone';

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);
