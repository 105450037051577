<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { get } from 'vuex-pathify';

export default {
  name: 'AppRoot',

  computed: {
    currentAccountId: get('common/currentAccountId'),
    advertiser: get('common/advertiser'),
  },
};
</script>

<style>
@import 'https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,600,700,900';

/* @import 'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700&display=swap'; */
</style>

<style lang="scss">
@import './assets/css/app.scss';
::v-deep .light-theme .dropdown {
  background-color: #ffffff;
  border: 1px solid #c5d2d8;
  color: #555;
}
</style>
<style lang="css">
@import './../node_modules/c3/c3.min.css';
</style>
