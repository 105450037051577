import numeral from 'numeral';

export default function formatNumber(number = 0, type = '', decimals) {
  const confirmNumber = Number(number);
  if (confirmNumber === Number.NaN) {
    return number;
  }

  const isKBig = confirmNumber >= 1000;
  const isMBig = confirmNumber >= 1000000;
  let formatting = `0.00a`;

  if (!decimals || (decimals !== '0' && decimals !== '00')) {
    // Default section, when no formatting is specified.
    switch (type.toUpperCase()) {
      case 'CURRENCY':
        formatting = isKBig ? '($0.0a)' : `($0.00a)`;
        break;
      case 'DECIMAL':
        formatting = isKBig ? '(0.0a)' : `(0.00a)`;
        break;
      case 'PERCENTAGE':
        number /= 100;
        formatting = '0%';
        break;
      case 'DECIMAL_PERCENTAGE':
        number /= 100;
        formatting = '0.00%';
        break;
      case 'RAW_PERCENTAGE':
        formatting = '0%';
        break;
      case 'FREQUENCY':
        formatting = `0.00a`;
        break;
      case 'RATIO':
        number /= 100;
        formatting = '(0.00)';
        break;
      case 'VISITS':
        if (isMBig) {
          formatting = '0.00a';
        } else {
          formatting = isKBig ? '0.0a' : '0a';
        }
        break;
      default:
        formatting = isKBig ? '0.0a' : `0a`;
        break;
    }
  } else {
    // Need to accommodate user provided decimals.
    switch (type.toUpperCase()) {
      case 'CURRENCY':
        formatting = `($0.${decimals}a)`;
        break;
      case 'DECIMAL':
        formatting = `(0.${decimals}a)`;
        break;
      case 'PERCENTAGE':
      case 'DECIMAL_PERCENTAGE':
        number /= 100;
        formatting = `0.${decimals}%`;
        break;
      case 'RAW_PERCENTAGE':
        formatting = `0.${decimals}%`;
        break;
      case 'FREQUENCY':
      case 'VISITS':
      default:
        formatting = `0.${decimals}a`;
        break;
      case 'RATIO':
        number /= 100;
        formatting = '(0.00)';
        break;
    }
  }

  const num = numeral(number);
  return type.toUpperCase() === 'RATIO' ? `${num.format(formatting)}:1` : num.format(formatting);
}

export function formatNumberAsFullNumber(number = 0, type = '', decimals) {
  const confirmNumber = Number(number);
  if (Number.isNaN(confirmNumber)) {
    return number;
  }
  let formatting = '0,0.[00]';
  if (!decimals || (decimals !== '0' && decimals !== '00')) {
    // Default section, when no formatting is specified.
    switch (type.toUpperCase()) {
      case 'CURRENCY':
        formatting = '$0,0.[00]';
        break;
      case 'DECIMAL':
        formatting = '0,0.[00]';
        break;
      case 'PERCENTAGE':
        number /= 100;
        formatting = '0.[00]%';
        break;
      case 'DECIMAL_PERCENTAGE':
        number /= 100;
        formatting = '0.0000%';
        break;
      case 'RAW_PERCENTAGE':
        formatting = '0.[00]%';
        break;
      case 'FREQUENCY':
        formatting = '0.[00]';
        break;
      case 'VISITS':
        formatting = '0,0.[00]';
        break;
      case 'RATIO':
        number /= 100;
        formatting = '0,0.[0000]';
        break;
      default:
        formatting = '0,0.[00]';
        break;
    }
  } else {
    // Need to accommodate user provided decimals.
    switch (type.toUpperCase()) {
      case 'CURRENCY':
        formatting = `($0,0.[${decimals}])`;
        break;
      case 'DECIMAL':
        formatting = `(0,0.[${decimals}])`;
        break;
      case 'PERCENTAGE':
      case 'DECIMAL_PERCENTAGE':
        number /= 100;
        formatting = `0.[${decimals}]%`;
        break;
      case 'RAW_PERCENTAGE':
        formatting = `0.[${decimals}]%`;
        break;
      case 'RATIO':
        number /= 100;
        formatting = '0,0.[0000]';
        break;
      case 'FREQUENCY':
      case 'VISITS':
      default:
        formatting = `0,0.[${decimals}]`;
        break;
    }
  }

  const num = numeral(number);
  return type.toUpperCase() === 'RATIO' ? `${num.format(formatting)}:1` : num.format(formatting);
}

export function formattedNumberValue(number = '0') {
  const isPercentageValue = number && number.endsWith('%');
  const num = numeral(isPercentageValue ? number.replace('%', '') : number);
  return num.value();
}
