/**
 * All application-specific configuration should go here. Where possible, all
 * `process.env` refs should be contained to this file.
 *
 * This includes all 'tunable' settings.
 */

import { initAxios } from 'adready-api/init';
import axios from 'axios';
import { initSentry } from './init/sentry';
import { getQueryParams } from '~/helpers/global/url-helpers';
import { DEFAULT_LOGIN_IMG, DEFAULT_LOGO_URL } from './constant';
import { setCssVariable } from './helpers/global/misc-helpers';

// App specific config
const config = {
  // When enabled, allows selecting all target types for all products
  DEBUG_TARGETING: process.env.VUE_APP_DEBUG_TARGETING === '1',

  stackDriver: {
    key: process.env.VUE_APP_STACKDRIVER_KEY,
    projectId: 'kickstart-233015',
    service: 'blip',
  },

  async init() {
    // Load some configs dynamically
    //
    // This solves the problem of baking a docker image with a static JS build
    // but still change certain config values dynamically based on the
    // environment on app startup.
    //
    // ALL per-ENV vars *MUST* be loaded from this endpoint!
    let flipConfigSuccess;
    await axios
      .get('/api/flipconfig.json')
      .then(async (res) => {
        Object.assign(config, res.data);
        config.CONFIG_API_URL ||= process.env.VUE_APP_CONFIG_API_URL;
        config.SENTRY_DSN ||= process.env.SENTRY_DSN;
        config.ENV_NAME ||= process.env.ENV_NAME;
        config.GA_ID ||= process.env.VUE_APP_GA_ID;
        config.LUCID_IMPRESSION_COUNT ||= process.env.VUE_APP_LUCID_IMPRESSION_COUNT;
        flipConfigSuccess = true;
      })
      .catch(async (e) => {
        // local from env in case api fails on local
        flipConfigSuccess = false;
        console.error('Failed to load /api/flipconfig.json:', e);
        config.CONFIG_API_URL = process.env.VUE_APP_CONFIG_API_URL;
        config.ADREADY_URL = process.env.VUE_APP_ADREADY_URL;
        config.COOKIE_DOMAIN = process.env.VUE_APP_COOKIE_DOMAIN;
        config.SENTRY_DSN = process.env.SENTRY_DSN;
        config.ENV_NAME = process.env.ENV_NAME;
        config.GA_ID = process.env.VUE_APP_GA_ID;
        config.FLIP_NAME = 'Flip';
        config.APP_URL = process.env.VUE_APP_CONSOLE_URL;
        config.LUCID_IMPRESSION_COUNT = process.env.VUE_APP_LUCID_IMPRESSION_COUNT;
        const apiURL = `${config.ADREADY_URL}/api`;
        initAxios(apiURL, config);
        initSentry(config.SENTRY_DSN, config.ENV_NAME);
      });

    if (!flipConfigSuccess) {
      return config;
    }

    const embed = window.location.href.includes('embed=true');
    const configApiUrl = `${config.CONFIG_API_URL}/api/config?domain=${window.location.hostname}&embed=${embed}&app=4`;
    return axios
      .get(configApiUrl, { withCredentials: true })
      .then(async (res) => {
        const serverConfig = res.data;
        config.ADREADY_URL = serverConfig.params.apiUrl;
        config.COOKIE_DOMAIN = serverConfig.params.domain;
        config.FLIP_NAME = serverConfig.params.name;
        config.FLIP_URL = serverConfig.params.consoleUrl;
        config.FLIP_FAVICON_URL = serverConfig.params.favIconUrl;
        config.SITE_LOGO_URL = serverConfig.params.siteLogoUrl || DEFAULT_LOGO_URL;
        config.PRIMARY_COLOR = serverConfig.theme.primaryColor;
        config.LOGIN_PAGE_IMAGE_URL = serverConfig.params.loginPageImageUrl || DEFAULT_LOGIN_IMG;
        config.GOOGLE_SIGN_IN = serverConfig.params.googleSignIn === '1';
        config.BUTTON_TEXT = serverConfig.theme.buttonText;
        config.APP_URL = serverConfig.params.consoleUrl;
        config.DOC_LOGO_URL = serverConfig.params.docLogoUrl;
        const apiURL = `${config.ADREADY_URL}/api`;
        initAxios(apiURL, config);
        setCssVariable(config);
        initSentry(config.SENTRY_DSN, config.ENV_NAME);
      })
      .catch((e) => {
        // local from env in case api fails on local
        console.error(`Failed to load ${configApiUrl}`, e);
      });
  },
  initializePendo() {
    if (!config?.pendoEnabled?.()) {
      console.log('Pendo credentials not found');
      return;
    }
    (function pendoFunction1(apiKey) {
      (function pendoFunction2(p, e, n, d, o) {
        let w;
        let x;
        p[d] = p[d] || {};
        o = p[d];
        o._q = o._q || [];
        const v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
        for (w = 0, x = v.length; w < x; ++w)
          (function pendoFunction3(m) {
            o[m] =
              o[m] ||
              function pendoFunction4(...args) {
                o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(args, 0)));
              };
          })(v[w]);
        const y = e.createElement(n);
        y.async = !0;
        y.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`;
        const z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);
      })(window, document, 'script', 'pendo');
    })(config.PENDO_API_KEY);
  },
  gaEnabled() {
    return this.GA_ID && this.GA_ID !== 'undefined';
  },
  pendoEnabled() {
    const params = getQueryParams(window.location.href);
    return !params?.embed && this.PENDO_API_KEY && this.PENDO_API_KEY !== 'undefined';
  },
};

export default config;
